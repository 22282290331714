import { render, staticRenderFns } from "./ReEvaluateSubmission.vue?vue&type=template&id=1335b372&scoped=true&"
import script from "./ReEvaluateSubmission.vue?vue&type=script&lang=js&"
export * from "./ReEvaluateSubmission.vue?vue&type=script&lang=js&"
import style0 from "./ReEvaluateSubmission.vue?vue&type=style&index=0&id=1335b372&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1335b372",
  null
  
)

export default component.exports