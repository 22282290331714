<template>
  <div>
    <GoBack />

    <!-- Header -->
    <div v-if="user" class="d-flex align-center justify-space-between mb-2">
      <div class="d-flex align-center">
        <base-avatar :name="user.full_name" :photo="user.photo" size="50" />
        <div class="ml-5">
          <p class="text-h4">
            {{ user.full_name }}
          </p>
          <p class="lightCarbon--text font-weight-regular caption">
            {{ user.email }}
          </p>
        </div>
      </div>
      <v-btn
        v-if="review.uuid === ''"
        color="primary"
        depressed
        x-large
        @click="createInitialReview"
      >
        Start Reviewing
      </v-btn>
    </div>

    <v-sheet v-if="serverFailure" class="pa-5 d-flex flex-column align-center">
      <p class="text-h5 font-weight-medium">
        There was some issue in getting the submission.
      </p>
      <p>
        Please try refreshing the page.
      </p>
    </v-sheet>

    <v-row v-else class="container-classes">
      <v-col cols="12" md="6" xl="7">
        <submission-details
          :assignment="assignment"
          :loading="loading"
          :submission="submission"
        />
      </v-col>
      <v-col
        v-if="showPlagiarismView && !reviewAlreadyExists && review.uuid !== ''"
        cols="12"
        md="6"
        xl="5"
      >
        <validate-plagiarism
          :data="plagiarismData"
          :review="review"
          :submission-id="submission.uuid"
          :submission-list="allSubmissionsList"
          @change-submission="getSubmissionById($event)"
          @continue="showPlagiarismView = false"
          @plagiarism="handleMarkPlagiarised($event)"
          @update="updatePlagiarismData($event)"
        />
      </v-col>
      <v-col v-else-if="assignment.uuid" cols="12" md="6" xl="5">
        <re-evaluate-submission
          v-if="iqaReview"
          :assignment-id="assignment.uuid"
          :iqa-review="iqaReview"
          :review="review"
          :submission-id="submission.uuid"
          :submission-list="allSubmissionsList"
          @change-submission="getSubmissionById($event)"
          @submit="handleReEvaluationSubmit"
        />
        <evaluate-submission
          v-else
          :assignment-id="assignment.uuid"
          :is-specific-submission="isSpecificSubmission"
          :previous-submission-id="previousSubmissionId"
          :review="review"
          :review-already-exists="reviewAlreadyExists"
          :submission-id="submission.uuid"
          :submission-list="allSubmissionsList"
          @change-submission="getSubmissionById($event)"
          @plagiarism="handleMarkPlagiarised($event)"
          @submit="handleReviewSubmit($event)"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import GoBack from "@/components/shared/GoBack"
import SubmissionDetails from "@/components/dashboard/assignments/SubmissionDetails"
import EvaluateSubmission from "@/components/dashboard/assignments/EvaluateSubmission"
import ReEvaluateSubmission from "@/components/dashboard/assignments/ReEvaluateSubmission"
import ValidatePlagiarism from "@/components/dashboard/assignments/ValidatePlagiarism"

import { REVIEW_STATUS } from "@/utils/constants"
import filters from "@/utils/mixins/filters"
import urls from "@/utils/urls"

function initialState() {
  return {
    /**
     * Controls if the professor sees assignment or submission in the text editor
     * For submission, it works with selectedSubmissionId since there can be multiple submissions of an assignment
     * But there will always be one assignment.
     * Can be one of ["assignment", "submission"]
     */
    // UUID of the currently selected submission
    selectedSubmissionId: "",

    // Assignment meta-data
    assignment: {
      title: "Assignment",
      course: "Course",
    },

    // Controls whether to show plagiarism view or rubrics
    showPlagiarismView: false,

    // Student details
    user: null,

    /**
     * If submissionId is present in the URL or not
     * If true, then there is no 'Next' submission.
     * 'Submit and Next' functionality will be disabled
     */
    isSpecificSubmission: false,

    // Stores currently selected submission (determined from selectedSubmissionId)
    submission: {},

    // List of all submissions of the student (ordered by date i.e latest submission at index 0)
    allSubmissionsList: [],

    /**
     * Stores review (if already exists) of the submission
     * Submission is considered reviewed only if a review object exists with any status other than 'not_reviewed' or 'under_review'
     * All possible values of review.status are:
     *    not_reviewed
     *    under_review
     *    accepted
     *    rejected
     */
    review: {
      uuid: "",
      comments: "",
      marks: 0,
      status: null,
    },

    iqaReview: null,

    // Tracks submission loading
    loading: false,

    serverFailure: false,

    plagiarismData: {},
  }
}

export default {
  /**
   * IMPORTANT:
   * In case there are no assignments available to check, the professor is sent back to the previous screen
   */
  name: "ViewSubmission",

  components: {
    GoBack,
    ValidatePlagiarism,
    SubmissionDetails,
    EvaluateSubmission,
    ReEvaluateSubmission,
  },

  mixins: [filters],

  /**
   * Moved initialState into it's own function because needed to reset state later in the component
   * When professor submits the review and wants to see the next submission (Submit and Next)
   */
  data: () => initialState(),

  computed: {
    previousSubmissionId() {
      if (this.allSubmissionsList.length > 1)
        return this.allSubmissionsList[1].uuid
      return null
    },

    reviewAlreadyExists() {
      let reviewExists = false
      const { uuid, status } = this.review
      if (!uuid) {
        reviewExists = false
      } else if (status === REVIEW_STATUS.UNDER_REVIEW) {
        reviewExists = false
      } else {
        reviewExists = true
      }
      return reviewExists
    },
  },

  mounted() {
    this.isSpecificSubmission = !!this.$route.params.submissionId

    if (this.isSpecificSubmission)
      this.getSubmissionById(this.$route.params.submissionId, true)
    else this.getRandomSubmission()
  },

  methods: {
    updatePlagiarismData(newData) {
      this.plagiarismData = newData

      // Don't show plagiarism view if professor has already validated plagiarism once
      this.showPlagiarismView = !newData.review_status
    },
    async getRandomSubmission() {
      this.loading = true
      this.submission = {}

      try {
        const res = await this.$http.get(urls.submissions.getRandomSubmission, {
          params: {
            assignment_id: this.$route.query.assignmentId,
            user_id: this.$route.query.studentId,
          },
        })
        const data = res.data.data
        this.getPreviousSubmissionsList(data.assignment.uuid, data.user.uuid)
        this.setSubmissionDataInState(data)

        // this.getPlagiarismData(data.uuid)
      } catch (error) {
        console.error(error)

        if (error.serverFailure) this.serverFailure = true

        if (error.response)
          if (error.response.data.data)
            if (error.response.data.data.all_checked) {
              /**
               * If all_checked is true, that signifies that there are no more assignments available to check for
               * the particular set of filters.
               * So, we send the professor back to the list screen.
               */
              this.$toast.info("There are no pending assigments to be checked.")
              this.$router.go(-1)
            }
      } finally {
        this.loading = false
      }
    },

    handleMarkPlagiarised({
      uuid,
      marks,
      status,
      comments,
      date_published,
      professor,
    }) {
      this.review = {
        rubrics: [],
        uuid,
        marks: marks.toFixed(2),
        status,
        comments,
        date_published,
        professor,
      }
      this.getPreviousSubmissionsList(this.assignment.uuid, this.user.uuid)
      if (!this.isSpecificSubmission) {
        this.getRandomSubmission()
      }
    },

    async getPreviousSubmissionsList(assignmentId, studentId) {
      try {
        const res = await this.$http.get(
          urls.submissions.getPreviousSubmissionsList(assignmentId, studentId)
        )
        const data = res.data.data
        data.sort((a, b) => (a.date_created < b.date_created ? 1 : -1))
        this.allSubmissionsList = data
      } catch (error) {
        console.error(error)
      }
    },

    async getSubmissionById(
      submissionId,
      fetchPreviousSubmissionsList = false
    ) {
      this.loading = true
      this.submission = {}
      try {
        const res = await this.$http.get(
          urls.submissions.getSubmissionById(submissionId),
          {
            params: {
              fields: [
                "review",
                "iqa_review",
                "review_rubrics",
                "iqa_review_rubrics",
              ],
            },
          }
        )
        const data = res.data.data
        if (fetchPreviousSubmissionsList) {
          this.getPreviousSubmissionsList(data.assignment.uuid, data.user.uuid)
        }
        this.setSubmissionDataInState(data)

        // this.getPlagiarismData(submissionId)
      } catch (error) {
        console.error(error)
        if (error.serverFailure) this.serverFailure = true
      } finally {
        this.loading = false
      }
    },

    // async getPlagiarismData(submissionId) {
    //   try {
    //     const res = await this.$http.get(urls.plagiarism.get(submissionId))
    //     const data = res.data.data

    //     data.submission_text = (data.submission_text || []).map(x => ({
    //       ...x,
    //       type: x.professor_remark || PLAGIARISM_TYPE.PLAGIARISED,
    //     }))
    //     data.submission_table = (data.submission_table || []).map(x => ({
    //       ...x,
    //       type: x.professor_remark || PLAGIARISM_TYPE.PLAGIARISED,
    //     }))
    //     data.submission_image = (data.submission_image || []).map(x => ({
    //       ...x,
    //       type: x.professor_remark || PLAGIARISM_TYPE.PLAGIARISED,
    //     }))

    //     this.updatePlagiarismData(data)
    //   } catch (error) {
    //     this.showPlagiarismView = false
    //     console.error(error)
    //     if (error.serverFailure) this.serverFailure = true
    //   } finally {
    //     this.loading = false
    //   }
    // },

    setSubmissionDataInState(data) {
      this.assignment = data.assignment
      this.submission = {
        uuid: data.uuid,
        text: data.submission,
        attachments: data.attachments,
        status: data.status,
        references: data.references,
      }
      this.user = data.user
      this.selectedSubmissionId = data.uuid

      if (data.review) {
        if (data.review.status === REVIEW_STATUS.NOT_REVIEWED) {
          // this.createInitialReview()
        } else {
          this.review = {
            rubrics: this.formatRubrics(data.review_rubrics),
            uuid: data.review.uuid,
            comments: data.review.comments,
            marks: data.review.marks ? data.review.marks.toFixed(2) : null,
            status: data.review.status,
            evaluationStatus: data.review.evaluation_status,
            professor: data.review.professor,
            date_published: data.review.date_published,
          }
          if (data.iqa_review) {
            this.iqaReview = {
              rubrics: this.formatRubrics(data.iqa_review_rubrics),
              uuid: data.iqa_review.uuid,
              comments: data.iqa_review.comments,
              marks: data.iqa_review.marks
                ? data.iqa_review.marks.toFixed(2)
                : null,
              status: data.iqa_review.status,
              evaluatorNotes: data.evaluator_notes ?? "",
              rejectionReason: data.rejection_reason ?? "",
            }
          } else {
            this.iqaReview = data.iqa_review
          }
        }
      } else {
        this.review = {
          rubrics: [],
          uuid: "",
          comments: "",
          marks: 0,
          status: null,
        }
      }
    },

    formatRubrics(rubrics) {
      return rubrics
        ? rubrics
            .map(value => ({
              marked: value.marked !== null,
              id: value.id,
              criteria: value.criteria,
              task: value.task,
              cells: value.all_comments
                .map(cell => {
                  return {
                    uuid: cell.uuid,
                    scale: cell.scale,
                    text: cell.comments,
                    professorSelected: value.marked?.uuid === cell.uuid,
                  }
                })
                .sort((a, b) => {
                  if (a.scale > b.scale) return 1
                  if (b.scale > a.scale) return -1
                }),
            }))
            .sort((a, b) =>
              `${a.task} ${a.criteria}` > `${b.task} ${b.criteria}` ? 1 : -1
            )
        : []
    },

    async createInitialReview() {
      /**
       * Creates an initial review object in the backend with status 'under_review'
       * This signifies that the professor has seen the submission
       * the student now cannot edit their submission
       */
      try {
        const res = await this.$http.post(
          urls.submissions.createInitialReview,
          {
            submission_id: this.selectedSubmissionId,
            user_id: this.user.uuid,
            assignment_id: this.assignment.uuid,
          }
        )
        const data = res.data.data
        this.review = {
          rubrics: [],
          uuid: data.uuid,
          comments: data.comments,
          marks: data.marks,
          status: data.status,
          professor: data.professor,
        }
      } catch (e) {
        console.error(e)
      }
    },

    async handleReviewSubmit({ data, fetchNextSubmission, done }) {
      try {
        const res = await this.$http.post(urls.submissions.submitReview, data)
        const { marks, status, uuid, professor, date_published } = res.data.data
        this.$toast.info("Review submitted sucessfully")
        this.review = {
          uuid,
          marks: marks.toFixed(2),
          status,
          comments: data.professor_comments,
          professor,
          date_published,
        }
        if (fetchNextSubmission) {
          this.resetState()
          this.getRandomSubmission()
        } else {
          this.getSubmissionById(this.selectedSubmissionId)
          this.getPreviousSubmissionsList(this.assignment.uuid, this.user.uuid)
        }
      } catch (e) {
        console.error(e)
        if (e.response?.data.errors?.professor_comments)
          this.$toast.error(
            `Comments: ${e.response.data.errors.professor_comments[0]}`
          )
      } finally {
        done()
      }
    },

    handleReEvaluationSubmit(data) {
      this.review.evaluationStatus = data.evaluationStatus
    },

    resetState() {
      Object.assign(this.$data, initialState())
    },
  },
}
</script>

<style scoped lang="scss">
.container-classes::v-deep {
  .assignment-container-scrollbars {
    &::-webkit-scrollbar {
      width: 8px; /* width of the entire scrollbar */
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #00000017; /* color of the tracking area */
    }

    &::-webkit-scrollbar-thumb {
      background-color: #8d96b2; /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
      border: 3px solid #00000017; /* creates padding around scroll thumb */
    }
  }

  .container-fixed-height {
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      height: calc(100vh - 240px);
    }
  }
}
</style>
