<template>
  <div class="froala-wrapper-container">
    <div v-if="loading" class="progress-container">
      <v-progress-circular
        color="primary"
        indeterminate
        :size="50"
        :width="4"
      />
    </div>
    <froalaView
      class="froala-view-editor-container"
      :config="froalaConfig"
      :value="text"
    />
  </div>
</template>

<script>
export default {
  name: "DisabledEditor",

  props: {
    text: { type: String, default: "" },
    loading: Boolean,
    // allowSelect: { type: Boolean, default: false },
  },

  data: () => ({
    froalaConfig: {
      key: process.env.VUE_APP_FROALA_EDITOR_API_KEY,
      attribution: false,
      toolbarButtons: {},
      charCounterCount: false,
      placeholderText: "Submission",
      events: {
        "edit.on": function() {
          this.edit.off()
        },
      },
    },
  }),
}
</script>

<style lang="scss">
.froala-wrapper-container {
  background: white;
  width: 100%;
}

.froala-view-editor-container {
  position: relative;

  /* 
  This is needed because some elements of the submission might have position: fixed elements
  They flow out of the container, into the entire viewport. So, to avoid that we override them with
  position: absolute
  */
  *[style*="position: fixed;"] {
    position: absolute !important;
  }
  *[style*="position:fixed;"] {
    position: absolute !important;
  }

  /* This is needed because some elements of the submission have negative margins and flow outside of the froala editor container */
  & > * {
    margin-left: 0px !important;
  }
}

/* .fr-element.fr-disabled {
  user-select: text !important;
  -webkit-user-select: text !important;
} */
.progress-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}
</style>
