var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({class:{ 'menu-button': true, 'menu-button-expand': _vm.open }},'div',attrs,false),on),[_c('v-icon',{staticClass:"btn-icon",attrs:{"large":""}},[_vm._v("mdi-segment")]),_c('p',{staticClass:"view-all-text"},[_vm._v("View All Submissions")])],1)]}}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-list',_vm._l((_vm.submissionList),function(item,index){return _c('v-list-item',{key:index,class:{
        'list-sub-item': true,
        primary: item.uuid === _vm.submissionId,
      },attrs:{"color":"red"},on:{"click":function($event){item.uuid === _vm.submissionId ? () => {} : _vm.$emit('change', item.uuid)}}},[_c('v-list-item-content',{class:{
          'white--text': item.uuid === _vm.submissionId,
        }},[_c('v-list-item-title',[_vm._v(" Submission "+_vm._s(_vm.submissionList.length - index)+" "+_vm._s(index === 0 ? "(Latest)" : "")+" ")]),_c('v-list-item-subtitle',{class:{
            'white--text': item.uuid === _vm.submissionId,
          }},[_vm._v(" Score: "+_vm._s(item.marks == null ? "Not scored yet" : item.marks.toFixed(2))+" ")])],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }