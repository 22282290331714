<template>
  <div>
    <div
      v-if="!loading && !rubricsNotFound"
      class="review-container container-fixed-height"
    >
      <v-row class="header" no-gutters>
        <v-col align-self="center" class="mx-6">
          <div class="text-h6">Review Submission</div>
          <span class="text-subtitle-2 font-weight-regular">
            Score: {{ review.marks ? review.marks : "Not yet scored" }}
          </span>
          <span
            v-if="review.status"
            class="text-subtitle-2 font-weight-regular text-capitalize"
          >
            | Status:
            {{ review.status.replace(/[-_]/g, " ") }}
            <span>
              <br />
              Reviewer: {{ review.professor }}
              <span v-if="review.date_published">
                |
                {{ moment(review.date_published).format("ll") }}
              </span>
            </span>
          </span>
        </v-col>

        <v-col cols="auto">
          <submissions-list-menu
            :submission-id="submissionId"
            :submission-list="submissionList"
            @change="handleSubmissionClick"
          />
        </v-col>
      </v-row>

      <v-divider />

      <div
        v-if="mountedReview.rubrics.length > 0"
        class="content assignment-container-scrollbars pt-6 px-6"
      >
        <review-rubric
          :disabled="reviewAlreadyExists || !canReview"
          :rubrics="mountedReview.rubrics"
          @mark="markRubric($event)"
        />

        <v-row v-if="canReview" no-gutters>
          <v-col class="mb-7">
            <div class="text-h6 font-weight-regular mb-3 mt-5">
              Comments for this submission
            </div>
            <v-textarea
              auto-grow
              name="comments"
              outlined
              placeholder="Leave your remarks"
              :readonly="reviewAlreadyExists"
              row-height="30"
              rows="8"
              :value="
                reviewAlreadyExists ? review.comments : currentReview.comments
              "
              @input="currentReview.comments = $event"
            />
            <v-row>
              <v-col cols="6" :lg="isSpecificSubmission ? 4 : 6">
                <v-btn
                  block
                  class="font-weight-regular"
                  :disabled="
                    submitLoading ||
                      reviewAlreadyExists ||
                      !previousSubmissionId
                  "
                  elevation="0"
                  :loading="copyLoading"
                  outlined
                  @click="copyPreviousSubmissionRubrics"
                >
                  Copy From Previous
                </v-btn>
              </v-col>

              <v-col cols="6" :lg="isSpecificSubmission ? 4 : 6">
                <plagiarism
                  class="font-weight-regular"
                  :disabled="reviewAlreadyExists || submitLoading"
                  :review-id="review.uuid"
                  :submission-id="submissionId"
                  @submit="$emit('plagiarism', $event)"
                />
              </v-col>

              <v-col v-if="!isSpecificSubmission" cols="6">
                <v-tooltip
                  bottom
                  :disabled="canSubmit || reviewAlreadyExists"
                  :open-delay="0"
                >
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      <v-btn
                        block
                        class="font-weight-regular"
                        color="primary"
                        :disabled="!canSubmit || reviewAlreadyExists"
                        elevation="0"
                        :loading="submitLoading"
                        @click="submitReview(true)"
                      >
                        Submit and Next
                      </v-btn>
                    </span>
                  </template>
                  <span class="caption">
                    Make sure you have marked all the rubrics and left a remark
                  </span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" :lg="isSpecificSubmission ? 4 : 6">
                <v-tooltip
                  v-if="!canSubmit || reviewAlreadyExists"
                  bottom
                  :disabled="reviewAlreadyExists"
                  :open-delay="0"
                >
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      <v-btn
                        block
                        class="font-weight-regular"
                        color="primary"
                        disabled
                        elevation="0"
                      >
                        Submit
                      </v-btn>
                    </span>
                  </template>
                  <span class="caption">
                    Make sure you have marked all the rubrics and left a remark
                  </span>
                </v-tooltip>

                <confirmation-dialog
                  v-else
                  ref="submitReviewDialog"
                  title="Submit review?"
                  @confirm="submitReview(false)"
                >
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn
                      v-bind="attrs"
                      block
                      class="font-weight-regular"
                      color="primary"
                      elevation="0"
                      :loading="submitLoading"
                      v-on="on"
                    >
                      Submit
                    </v-btn>
                  </template>

                  This will notify the student that their submission has been
                  reviewed. After confirming, you cannot edit the review.
                </confirmation-dialog>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <div v-else class="d-flex justify-center mb-4">
          <h3>
            Press the Start Review button to start reviewing this submission.
          </h3>
        </div>
      </div>
    </div>
    <div v-else class="pa-5 review-container container-fixed-height">
      <div
        v-if="rubricsNotFound"
        class="d-flex justify-center align-center full-height"
      >
        <h3>Remarks could not be loaded.</h3>
      </div>
      <skeleton-loading v-else-if="loading" :items="9" />
    </div>
  </div>
</template>

<script>
import Plagiarism from "@/components/dashboard/assignments/Plagiarism"
import ReviewRubric from "@/components/dashboard/assignments/ReviewRubric"
import SkeletonLoading from "@/components/dashboard/assignments/SkeletonLoading"
import SubmissionsListMenu from "@/components/dashboard/assignments/SubmissionsListMenu"
import ConfirmationDialog from "@/components/shared/ConfirmationDialog"
import urls from "@/utils/urls"
import { REVIEW_STATUS } from "@/utils/constants"

export default {
  name: "EvaluateSubmission",

  components: {
    Plagiarism,
    ReviewRubric,
    SkeletonLoading,
    SubmissionsListMenu,
    ConfirmationDialog,
  },

  props: {
    assignmentId: {
      type: String,
      required: true,
    },

    /**
     * This ID is used for "Copy from previous" functionality
     */
    previousSubmissionId: {
      type: String,
      default: "",
    },

    /**
     * Boolean which tells if the submission is a random one or a specifically selected one.
     */
    isSpecificSubmission: {
      type: Boolean,
      default: true,
    },

    /**
     * Contains a list of all the submsissions for an assignment by a single user.
     */
    submissionList: {
      type: Array,
      required: true,
    },

    /**
     * Current submission's ID.
     */
    submissionId: {
      type: String,
      default: "",
    },

    reviewAlreadyExists: {
      type: Boolean,
      default: false,
    },

    /**
     * Review object of the currently mounted submission
     * If review.uuid is null, then the review does not exist.
     * If review.uuid is not null but review.status is "under_review", then again, then the review does not exist.
     * If review.status is one of ["accepted", "rejected", "plagiarised"], then review exists.
     */
    review: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    REVIEW_STATUS,
    dialog: false,

    /**
     * Handles loading state for the entire component
     */
    loading: false,
    submitLoading: false,

    /**
     * Button loader for the 'Copy from Previous' functionality
     */
    copyLoading: false,

    /**
     * Object that stores the review that the user is currently marking
     * This will remain empty when there is no submission to review.
     */
    currentReview: {
      rubrics: [],
      comments: "",
    },

    /**
     * When user is looking at a previous submission, the review of that submission will be stored here
     * By keeping the previous review data in a different state, we are able to preserve the markings
     * of the user (in currentReview)
     */
    previousReview: {
      rubrics: [],
      comments: "",
    },

    rubricsNotFound: false,
  }),

  computed: {
    canSubmit() {
      /**
       * Returns true if the user has marked all the rubrics and left a remark
       */
      return (
        this.currentReview.rubrics.filter(rubric => !rubric.marked).length ===
          0 && this.currentReview.comments.length > 0
      )
    },

    mountedReview() {
      /**
       * Decide which review to show on the screen.
       * If the user is looking at an older submission, then we mount the review for that older submission
       * else we mount the 'editable' review which the user can edit
       */
      return this.reviewAlreadyExists ? this.previousReview : this.currentReview
    },

    canReview() {
      return this.review.uuid !== ""
    },
  },

  watch: {
    review(newValue) {
      if (this.reviewAlreadyExists) {
        this.previousReview.comments = newValue.comments
          ? newValue.comments
          : ""
        this.previousReview.rubrics = newValue.rubrics

        this.loading = false
        if (newValue.status === REVIEW_STATUS.PLAGIARISED) {
          this.getRubrics()
        }
      } else {
        // this.getRubrics()
        this.loading = false
      }
    },
  },

  mounted() {
    if (this.reviewAlreadyExists) {
      this.previousReview.comments = this.review.comments
        ? this.review.comments
        : ""
      this.previousReview.rubrics = this.review.rubrics
    }
    this.getRubrics()
  },

  methods: {
    async copyPreviousSubmissionRubrics() {
      this.copyLoading = true
      try {
        const requestURL = urls.submissions.getSubmissionById(
          this.previousSubmissionId
        )

        const res = await this.$http.get(requestURL, {
          params: {
            fields: "review_rubrics",
          },
        })
        const data = res.data.data.review_rubrics

        const newRubrics = data
          .map(value => ({
            marked: true,
            id: value.id,
            criteria: value.criteria,
            task: value.task,
            cells: value.all_comments
              .map(cell => ({
                uuid: cell.uuid,
                scale: cell.scale,
                text: cell.comments,
                currentlySelected: value.marked.uuid === cell.uuid,
              }))
              .sort((a, b) => {
                if (a.scale > b.scale) return 1
                if (b.scale > a.scale) return -1
              }),
          }))
          .sort((a, b) =>
            `${a.task} ${a.criteria}` > `${b.task} ${b.criteria}` ? 1 : -1
          )

        // Have to override current markings of the user. Therefore, setting value in currentReview
        if (newRubrics.length > 0) {
          this.currentReview.rubrics = newRubrics
        } else {
          this.$toast.info(
            "Previous review was plagiarised. Rubrics are not available."
          )
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.copyLoading = false
      }
    },

    async getRubrics() {
      const isNotPlagiarised = this.review.status !== REVIEW_STATUS.PLAGIARISED

      if (this.reviewAlreadyExists && isNotPlagiarised) {
        return
      }

      this.loading = true

      try {
        let rawRubrics = []
        if (this.reviewAlreadyExists && isNotPlagiarised) {
          const res = await this.$http.get(
            urls.submissions.getSubmissionById(this.submissionId),
            {
              params: {
                fields: ["review_rubrics"],
              },
            }
          )
          rawRubrics = res.data.data.review_rubrics
        } else {
          const res = await this.$http.get(
            urls.assignments.getRubrics(this.assignmentId)
          )
          rawRubrics = res.data.data
        }

        if (rawRubrics.length === 0) this.rubricsNotFound = true

        const rubrics = rawRubrics
          .map(value => ({
            marked: false,
            id: value.id,
            criteria: value.criteria,
            task: value.task,
            cells: value.all_comments
              .map(cell => {
                return {
                  uuid: cell.uuid,
                  scale: cell.scale,
                  text: cell.comments,
                  professorSelected: false,
                }
              })
              .sort((a, b) => {
                if (a.scale > b.scale) return 1
                if (b.scale > a.scale) return -1
              }),
          }))
          .sort((a, b) =>
            `${a.task} ${a.criteria}` > `${b.task} ${b.criteria}` ? 1 : -1
          )

        if (this.reviewAlreadyExists) {
          this.previousReview = {
            ...this.previousReview,
            rubrics,
          }
        } else {
          this.currentReview = {
            ...this.currentReview,
            rubrics,
          }
        }
      } catch (e) {
        console.error(e)
        this.rubricsNotFound = true
      } finally {
        this.loading = false
      }
    },

    markRubric({ scale, criteria }) {
      if (this.reviewAlreadyExists) return

      const selectedCriteria = this.currentReview.rubrics.find(
        currentCriteria => currentCriteria.id === criteria
      )

      const newCells = selectedCriteria.cells.map(cell => {
        cell.currentlySelected = cell.scale === scale
        return cell
      })
      selectedCriteria.cells = newCells
      selectedCriteria.marked = true
      this.currentReview.rubrics = this.currentReview.rubrics.map(obj => {
        if (selectedCriteria.id === obj.id) {
          obj.cells = newCells
          obj.marked = true
        }
        return obj
      })
    },

    submitReview(fetchNextSubmission) {
      this.submitLoading = true
      const dialog = this.$refs.submitReviewDialog
      dialog.loading = true

      const markedRubricCellIds = this.currentReview.rubrics.map(value => {
        return value.cells.find(value => value.currentlySelected).uuid
      })

      const data = {
        review: this.review.uuid,
        rubric_cells: markedRubricCellIds,
        professor_comments: this.currentReview.comments,
      }

      const done = () => {
        this.submitLoading = false
        dialog.loading = false
        dialog.close()
      }

      this.$emit("submit", { data, fetchNextSubmission, done })
    },

    handleSubmissionClick(uuid) {
      if (uuid === this.submissionId) return

      this.loading = true
      this.$emit("change-submission", uuid)
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  min-height: 100px;
}

.full-height {
  height: 100%;
}

.review-container {
  background: white;
  border-radius: 5px;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .content {
    height: calc(100% - 100px);
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
