<template>
  <v-dialog
    v-model="dialog"
    content-class="confirmation-dialog"
    :max-width="width"
    :persistent="presistent"
  >
    <!-- button to open dialog -->
    <template v-slot:activator="{ on, attrs }">
      <slot :attrs="attrs" name="activator" :on="on">
        <v-btn
          v-bind="attrs"
          class="rounded"
          color="primary"
          depressed
          v-on="on"
        >
          Submit
        </v-btn>
      </slot>
    </template>

    <v-card>
      <v-container class="pa-6">
        <v-row dense>
          <!-- title and close button -->
          <v-col cols="11">
            <p v-show="title" class="text-h5 black--text font-weight-medium">
              {{ title }}
            </p>
          </v-col>

          <v-col cols="1">
            <v-btn class="confirmation-dialog-close-button" icon @click="close">
              <v-icon color="black">mdi-close</v-icon>
            </v-btn>
          </v-col>

          <!-- divider -->
          <v-col v-show="title" cols="12">
            <v-divider class="mt-1 mb-2" />
          </v-col>

          <!-- main content -->
          <v-col class="confirmation-dialog-content" cols="12">
            <slot />
          </v-col>

          <!-- actions -->
          <v-col class="mt-10" cols="12">
            <v-row class="confirmation-dialog-actions" dense justify="end">
              <v-col class="pr-sm-3" cols="12" sm="auto">
                <slot :loading="loading" name="cancel" :onClick="cancel">
                  <v-btn
                    depressed
                    :loading="loading"
                    outlined
                    @click.native="cancel"
                  >
                    Cancel
                  </v-btn>
                </slot>
              </v-col>

              <v-col cols="12" sm="auto">
                <slot :loading="loading" name="confirm" :onClick="confirm">
                  <v-btn
                    color="primary"
                    depressed
                    :loading="loading"
                    @click.native="confirm"
                  >
                    Confirm
                  </v-btn>
                </slot>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 * It renders a button by default which opens it
 * or progmatically call it as this.$refs.confirm.open()
 *
 * Handle Loading:
 * this.$refs.confirm.loading = true/false
 *
 * Close:
 * this.$refs.dialog.close()
 *
 */

export default {
  name: "ConfirmationDialog",

  props: {
    width: {
      type: Number,
      default: 550,
    },

    closeOnCancel: {
      type: Boolean,
      default: true,
    },

    presistent: {
      type: Boolean,
      default: true,
    },

    title: {
      type: String,
      default: "Are you sure?",
    },
  },

  data: () => ({
    dialog: false,
    loading: false,
  }),

  methods: {
    open() {
      this.dialog = true
    },

    cancel() {
      if (this.closeOnCancel) {
        return this.close()
      }
      return this.$emit("cancel")
    },

    close() {
      this.dialog = false
      this.$emit("close")
    },

    confirm() {
      this.$emit("confirm")
    },
  },
}
</script>

<style lang="scss">
.confirmation-dialog-actions {
  button {
    width: 100% !important;
    border-radius: 4px;
    box-shadow: none;
  }
}
.confirmation-dialog {
  border: 1px solid var(--v-anchor-base);
}

.confirmation-dialog-close-button {
  background: var(--v-secondary-base);
}
.confirmation-dialog-content {
  color: var(--v-lightCarbon-base);
}
</style>
