<template>
  <div class="text-center">
    <v-dialog v-model="dialog" class="" width="800">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          block
          color="error"
          depressed
          :disabled="disabled"
          :large="large"
          v-on="on"
        >
          Plagiarism Detected
        </v-btn>
      </template>

      <v-card
        class="text-center d-flex justify-center flex-column px-6 py-6"
        min-height="40vh"
      >
        <v-btn
          class="plagiarism-cross-btn"
          depressed
          fab
          x-small
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-text>
          <v-icon color="error" size="100">
            mdi-exclamation
          </v-icon>
          <p class="text-h5 black--text font-weight-black">
            Reject Submission for this assignment?
          </p>
          <p class="text--secondary">
            This action is not reversible
          </p>
        </v-card-text>
        <div class="d-flex align-center flex-column">
          <v-textarea
            v-model="submissionRemarks"
            background-color="#F8F8F8"
            class="plagiarism-text-area py-4 plagiarism-submission-remarks"
            color="black"
            label="Remarks for this Submission"
            :no-resize="true"
            rows="3"
          />
        </div>
        <v-card-actions class="d-flex justify-center">
          <v-btn depressed min-width="230" @click="dialog = false">
            CANCEL
          </v-btn>
          <v-btn
            color="error"
            depressed
            :disabled="!submissionRemarks.length > 0"
            min-width="230"
            @click="reject"
          >
            REJECT
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import urls from "@/utils/urls"
import { REVIEW_STATUS } from "@/utils/constants"

export default {
  name: "Plagiarism",

  props: {
    submissionId: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    reviewId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      dialog: false,
      submissionRemarks: "",
    }
  },
  methods: {
    async reject() {
      if (this.submissionRemarks.trim().length < 4) {
        this.$toast.error("Comments should have at least 4 characters.")
        return
      }

      try {
        const res = await this.$http.put(
          urls.assignments.markPlagiarised(this.reviewId),
          {
            status: REVIEW_STATUS.PLAGIARISED,
            comments: this.submissionRemarks,
          }
        )

        this.$toast.info("The submission has been marked as plagiarised")

        this.$emit("submit", res.data.data)

        this.dialog = false
      } catch (e) {
        if (e.response?.data.errors?.detail)
          this.$toast.error(e.response?.data.errors?.detail[0])
        if (e.response?.data.errors?.comments)
          this.$toast.error(e.response?.data.errors?.comments[0])
      }
    },
  },
}
</script>
<style lang="scss">
.plagiarism-cross-btn {
  position: absolute;
  top: 20px;
  right: 20px;
}

.plagiarism-text-area {
  min-width: 70%;
}

.plagiarism-submission-remarks
  .v-input__control
  .v-input__slot
  .v-text-field__slot {
  border: 1px solid rgb(216, 216, 216) !important;
  padding: 20px !important;
}

.v-application--is-ltr .plagiarism-submission-remarks .v-label {
  top: 15px !important;
  left: 20px !important;
}

.v-application--is-ltr .plagiarism-submission-remarks .v-label--active {
  top: 0px !important;
  right: auto !important;
  left: 5px !important;
}
</style>
