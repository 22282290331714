<template>
  <div>
    <div
      v-if="!loading && !professorRubricsNotFound && !iqaRubricsNotFound"
      class="review-container container-fixed-height"
    >
      <v-row class="header" no-gutters>
        <v-col align-self="center" class="mx-6">
          <div class="d-flex align-center">
            <p class="text-h6 mr-2">
              Re-evaluate Submission
            </p>

            <v-chip v-if="evaluationIsCompleted" color="success" label small>
              Completed
            </v-chip>
            <v-chip
              v-if="isPlagiarised"
              :class="{ 'ml-1': evaluationIsCompleted }"
              color="error"
              label
              small
            >
              Marked for Plagiarism by IQA
            </v-chip>
          </div>
          <span class="text-subtitle-2 font-weight-regular">
            Score: {{ review.marks ? review.marks : "Not yet scored" }}
          </span>
          <span
            v-if="review.status"
            class="text-subtitle-2 font-weight-regular"
          >
            | IQA Score:
            {{ iqaReview.marks ? iqaReview.marks : "Not yet scored" }}
          </span>
          <span
            v-if="review.status"
            class="text-subtitle-2 font-weight-regular text-capitalize"
          >
            | Status:
            {{ iqaReview.status.replace(/[-_]/g, " ") }}
            <span v-if="iqaReview.status !== REVIEW_STATUS.UNDER_REVIEW">
              <br />
              Reviewed By: {{ review.professor }}
              <span v-if="review.date_published">
                |
                {{ moment(review.date_published).format("ll") }}
              </span>
            </span>
          </span>
        </v-col>

        <v-col cols="auto">
          <submissions-list-menu
            :submission-id="submissionId"
            :submission-list="submissionList"
            @change="$emit('change-submission', $event)"
          />
        </v-col>
      </v-row>

      <v-divider />

      <v-row class="px-6">
        <v-col v-if="isPlagiarised" class="d-flex pb-1 align-center" cols="12">
          <v-icon>mdi-information-outline</v-icon>
          <p class="text--secondary text-body-2 ml-2">
            IQA review toggle has been disabled as they marked this submission
            for plagiarism.
          </p>
        </v-col>
        <v-col class="pr-16" cols="auto">
          <v-switch
            v-model="showProfessorRubrics"
            class="ma-0"
            color="#49B7CC"
            hide-details
            inset
            label="View Your Review"
          />
        </v-col>
        <v-col cols="auto">
          <v-switch
            v-model="showIqaRubrics"
            class="ma-0"
            :color="isPlagiarised ? '#A8A8A8' : '#9A7BBA'"
            :disabled="isPlagiarised"
            hide-details
            inset
            label="View IQA's Review"
          />
        </v-col>
      </v-row>

      <div
        v-if="rubrics.length > 0"
        class="content assignment-container-scrollbars pt-6 px-6"
      >
        <review-rubric
          disabled
          :rubrics="rubrics"
          :show-iqa-selected="showIqaRubrics"
          :show-professor-selected="showProfessorRubrics"
        />

        <v-row no-gutters>
          <v-col class="mb-7">
            <div class="text-h6 font-weight-regular mb-3 mt-5">
              {{ showProfessorComments ? "Your " : "IQA " }} comments for this
              submission
            </div>
            <div class="my-3">
              <v-btn
                class="rounded mr-4"
                :color="showProfessorComments ? 'pearlGray' : ''"
                depressed
                :outlined="!showProfessorComments"
                @click="showProfessorComments = true"
              >
                Your Comments
              </v-btn>
              <v-btn
                class="rounded"
                :color="!showProfessorComments ? 'pearlGray' : ''"
                depressed
                :outlined="showProfessorComments"
                @click="showProfessorComments = false"
              >
                IQA Comments
              </v-btn>
            </div>
            <v-textarea
              auto-grow
              name="comments"
              outlined
              placeholder="Previous remarks"
              readonly
              row-height="30"
              rows="6"
              :value="
                showProfessorComments ? review.comments : iqaReview.comments
              "
            />

            <div class="mt-5">
              <p class="text-h6 font-weight-regular mb-3 ">
                IQA Notes
              </p>
              <v-textarea
                name="evaluatorNotes"
                outlined
                placeholder="IQA Remarks"
                readonly
                rows="6"
                :value="
                  evaluationIsCompleted
                    ? iqaReview.rejectionReason
                    : iqaReview.evaluatorNotes
                "
              />
            </div>

            <v-row
              v-if="!evaluationIsCompleted && !evaluationIsChallenged"
              dense
              justify="end"
            >
              <v-col class="mr-sm-2" cols="12" sm="auto">
                <v-btn
                  block
                  class="rounded font-weight-regular"
                  elevation="0"
                  outlined
                  @click="handleChallengeOpen"
                >
                  Challenge Evaluation
                </v-btn>
              </v-col>

              <v-col cols="12" sm="auto">
                <confirmation-dialog
                  ref="acceptIQAReviewDialog"
                  title="Accept IQA's changes?"
                  @confirm="acceptIQAReview"
                >
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn
                      v-bind="attrs"
                      block
                      class="rounded font-weight-regular"
                      color="primary"
                      elevation="0"
                      v-on="on"
                    >
                      Accept Changes
                    </v-btn>
                  </template>
                  This will override your original review and IQA's review will
                  be shown to the student as the final review.
                </confirmation-dialog>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-expand-transition>
          <div
            v-show="challengeEvaluation"
            id="challenge-input-container"
            class="mb-7"
          >
            <v-divider />
            <v-row class="mb-2 mt-5" justify="space-between" no-gutters>
              <v-col>
                <div align-self="center" class="text-h5 font-weight-bold">
                  Challenge Evaluation
                </div>
              </v-col>
              <v-col align-self="center" cols="auto">
                <v-btn icon @click="challengeEvaluation = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  auto-grow
                  name="comments"
                  outlined
                  placeholder="Please leave your comments for the IQA to challenge their evaluation."
                  row-height="30"
                  rows="8"
                  :value="challengeEvaluationRemarks"
                  @input="challengeEvaluationRemarks = $event"
                />
              </v-col>
            </v-row>
            <v-row justify="end" no-gutters>
              <v-col cols="12" sm="auto">
                <confirmation-dialog
                  ref="submitChallengeDialog"
                  title="Submit Challenge?"
                  @confirm="challengeIQAReview"
                >
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn
                      v-bind="attrs"
                      block
                      class="font-weight-regular rounded"
                      color="primary"
                      depressed
                      :disabled="challengeEvaluationRemarks.length === 0"
                      v-on="on"
                    >
                      Submit Challenge
                    </v-btn>
                  </template>
                  This <b>DOES NOT</b> change the review. The IQA will be
                  notified of this challenge and will have the final call over
                  the decision.
                </confirmation-dialog>
              </v-col>
            </v-row>
          </div>
        </v-expand-transition>
      </div>
    </div>
    <div v-else class="pa-5 review-container container-fixed-height">
      <div
        v-if="professorRubricsNotFound || iqaRubricsNotFound"
        class="d-flex justify-center align-center full-height"
      >
        <h3 v-if="professorRubricsNotFound">
          Your remarks could not be loaded.
        </h3>
        <h3 v-else>IQA remarks could not be loaded.</h3>
      </div>
      <skeleton-loading v-else :items="9" />
    </div>
  </div>
</template>

<script>
import ReviewRubric from "@/components/dashboard/assignments/ReviewRubric"
import SkeletonLoading from "@/components/dashboard/assignments/SkeletonLoading"
import SubmissionsListMenu from "@/components/dashboard/assignments/SubmissionsListMenu"
import ConfirmationDialog from "@/components/shared/ConfirmationDialog"

import cloneDeep from "lodash/cloneDeep"

import urls from "@/utils/urls"
import { REVIEW_STATUS, EVALUATION_STATUS } from "@/utils/constants"

export default {
  name: "ReEvaluateSubmission",

  components: {
    ReviewRubric,
    SkeletonLoading,
    SubmissionsListMenu,
    ConfirmationDialog,
  },

  props: {
    assignmentId: {
      type: String,
      required: true,
    },

    /**
     * Contains a list of all the submsissions for an assignment by a single user.
     */
    submissionList: {
      type: Array,
      required: true,
    },

    /**
     * Current submission's ID.
     */
    submissionId: {
      type: String,
      required: true,
    },

    /**
     * Review object of the currently mounted submission
     * If review.uuid is null, then the review does not exist.
     * If review.uuid is not null but review.status is "under_review", then again, then the review does not exist.
     * If review.status is one of ["accepted", "rejected", "plagiarised"], then review exists. The above mention cases will not happen in this component.
     */
    review: {
      type: Object,
      default: () => {},
    },

    iqaReview: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    REVIEW_STATUS,

    /**
     * Array that stores the rubrics data.
     */
    rubrics: [],

    /**
     * Toggles between which comments to be shown, default is true which leads to professor's remarks to be displayed.
     */
    showProfessorComments: true,

    /**
     * Toggles for which marked cells are to be displayed as active.
     */
    showProfessorRubrics: false,
    showIqaRubrics: true,

    /**
     * Boolean which is toggled incase the attempt to fetch rubrics fails
     */
    professorRubricsNotFound: false,
    iqaRubricsNotFound: false,

    /**
     * Boolean which is toggled when the challenge evaluation button is clicked
     */
    challengeEvaluation: false,

    /**
     * Stores the comments when an IQA review is challenged
     */
    challengeEvaluationRemarks: "",

    /**
     * Handles loading state for the entire component
     */
    loading: true,
  }),

  computed: {
    evaluationIsCompleted() {
      return this.review.evaluationStatus === EVALUATION_STATUS.REVIEW_CHANGED
    },
    evaluationIsChallenged() {
      return this.review.evaluationStatus === EVALUATION_STATUS.CHALLENGED
    },
    isPlagiarised() {
      return this.iqaReview.status === REVIEW_STATUS.PLAGIARISED
    },
  },

  watch: {
    submissionId() {
      this.getRubrics()
    },
  },

  mounted() {
    this.getRubrics()
  },

  methods: {
    async getRubrics() {
      try {
        const professorRes = await this.$http.get(
          urls.submissions.getMarkedRubrics(this.review.uuid)
        )
        /**
         * Sorting both lists (professorData and iqaData) on criterion so their order is identical.
         * Two objects can have same criteria, so, also sorting on task to achieve consistent ordering between both lists
         */
        const professorData = this.sortData(
          this.sortData(professorRes.data.data, "criteria"),
          "task"
        )

        let iqaData
        if (this.isPlagiarised) {
          /**
           * If submission is plagiarised then IQA rubrics do not exist.
           * We just duplicate the IQA rubrics and delete the "marked" key
           */
          iqaData = professorData.map(obj => {
            const criteria = cloneDeep(obj)
            delete criteria["marked"]
            return criteria
          })
        } else {
          const iqaRes = await this.$http.get(
            urls.submissions.getIQAMarkedRubrics(this.iqaReview.uuid)
          )

          iqaData = this.sortData(
            this.sortData(iqaRes.data.data, "criteria"),
            "task"
          )
        }

        if (professorData.length === 0) this.professorRubricsNotFound = true
        if (iqaData.length === 0) this.iqaRubricsNotFound = true

        const rubrics = professorData.map((value, index) => ({
          marked: !this.isPlagiarised,
          id: value.id,
          criteria: value.criteria,
          task: value.task,
          cells: this.sortData(
            value.all_comments.map(cell => ({
              uuid: cell.uuid,
              scale: cell.scale,
              text: cell.comments,
              professorSelected: value.marked.uuid === cell.uuid,
              IQASelected: this.isPlagiarised
                ? false
                : iqaData[index].marked.uuid === cell.uuid,
              currentlySelected: false,
            })),
            "scale"
          ),
        }))

        this.rubrics = rubrics
        this.loading = false
      } catch (e) {
        console.error(e)
        this.professorRubricsNotFound = true
        this.iqaRubricsNotFound = true
      }
    },

    sortData(list, key) {
      return list.sort((a, b) => (a[key] > b[key] ? 1 : -1))
    },

    handleChallengeOpen() {
      this.challengeEvaluation = true
      const container = document.querySelector("#challenge-input-container")
      setTimeout(() => {
        container.scrollIntoView({ behavior: "smooth" })
      }, 400)
    },

    acceptIQAReview() {
      const data = {
        status: EVALUATION_STATUS.REVIEW_CHANGED,
      }
      const dialog = this.$refs.acceptIQAReviewDialog
      dialog.loading = true
      this.$http
        .put(urls.submissions.submitReEvaluation(this.review.uuid), data)
        .then(() => {
          dialog.close()
          this.$toast.success("Review accepted")
          this.$emit("submit", {
            evaluationStatus: data.status,
          })
          this.challengeEvaluation = false
        })
        .catch(e => {
          console.error(e)
        })
        .finally(() => {
          dialog.loading = false
        })
    },

    challengeIQAReview() {
      if (this.challengeEvaluationRemarks === "") {
        this.$toast.error("Please leave some comments for the IQA")
        return
      }
      const dialog = this.$refs.submitChallengeDialog
      dialog.loading = true

      const data = {
        status: EVALUATION_STATUS.CHALLENGED,
        challenger_comments: this.challengeEvaluationRemarks,
      }

      this.$http
        .put(urls.submissions.submitReEvaluation(this.review.uuid), data)
        .then(() => {
          this.challengeEvaluation = false
          this.$toast.success("Challenge submitted")
          dialog.close()
          this.$emit("submit", {
            evaluationStatus: data.status,
            challengerComments: data.challenger_comments,
          })
        })
        .catch(e => {
          console.error(e)
        })
        .finally(() => {
          dialog.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  min-height: 100px;
}

.full-height {
  height: 100%;
}

.review-container {
  background: white;
  border-radius: 5px;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .content {
    height: calc(100% - 190px);
    overflow-y: auto;
  }
}
</style>
