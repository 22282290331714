<template>
  <div>
    <div v-for="item in rubrics" :key="item.id">
      <v-row no-gutters>
        <div class="text-h6 font-weight-regular">
          ({{ item.task }}) {{ getCapitalizedStr(item.criteria) }}
        </div>
      </v-row>

      <v-row class="mb-4">
        <v-col
          v-for="cell in item.cells"
          :key="cell.uuid"
          cols="12"
          lg="4"
          md="6"
        >
          <div
            :class="[
              'review-cell px-3 pt-3',
              {
                'review-cell-selected': !disabled && cell.currentlySelected,
                'review-cell-inactive': !disabled && !cell.currentlySelected,
                'review-cell-professor':
                  showProfessorSelected && cell.professorSelected && disabled,
                'review-cell-iqa': showIqaSelected && cell.IQASelected,
                'review-cell-marked-by-iqa-and-professor':
                  showProfessorSelected &&
                  cell.professorSelected &&
                  showIqaSelected &&
                  cell.IQASelected,
                cursor: !disabled,
              },
            ]"
            @click="
              !disabled
                ? $emit('mark', { scale: cell.scale, criteria: item.id })
                : () => {}
            "
          >
            <p class="text-h6 font-weight-regular">
              {{ cell.scale }} {{ cell.scale === 1 ? "Point" : "Points" }}
            </p>
            <p class="mt-1 mb-3 text-subtitle-2 font-weight-regular">
              {{ cell.text }}
            </p>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import capitalize from "lodash/capitalize"

export default {
  name: "ReviewRubric",

  props: {
    rubrics: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showIqaSelected: {
      type: Boolean,
      default: false,
    },

    /**
     * This prop is used to toggle professor marked rubrics
     * It is true by default as we use this in EvaluateSubmissions Component aswell and there it is always true
     */
    showProfessorSelected: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    getCapitalizedStr(str) {
      return capitalize(str)
    },
  },
}
</script>

<style lang="scss" scoped>
.review-cell {
  border: 1px solid #274bc8;
  min-height: 150px;
  height: 100%;
  transition: 0.2s background, 0.2s color, 0.2s outline-color;
}

.cursor {
  cursor: pointer;
}

.review-cell-inactive {
  color: #274bc8;
}

.review-cell-professor {
  border: 1px solid #49b7cc;
  background: #49b7cc;
  color: white;
}

.review-cell-iqa {
  border: 1px solid #9a7bba;
  background: #9a7bba;
  color: white;
}

.review-cell-marked-by-iqa-and-professor {
  outline: 3px solid #49b7cc;
  outline-offset: 3px;
}

.review-cell-selected {
  border: 1px solid #274bc8;
  background: #274bc8;
  color: white;
  box-shadow: 0px 3px 20px #030a4861;
}
</style>
