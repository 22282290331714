<template>
  <div
    class="assignment-container-scrollbars container-fixed-height assignment-detail pa-5"
  >
    <p class="text-h6 text--carbon heading pa-3">
      {{ assignment.title | formatAssignmentTitle(assignment.short_title) }}
    </p>

    <DisabledEditor class="py-3" :text="assignment.instructions" />

    <div v-if="assignment.attachments" class="py-4">
      <span v-for="attachment in assignment.attachments" :key="attachment.id">
        <v-chip
          color="aluminium"
          :href="attachment.attachment"
          label
          target="_blank"
          text-color="primary"
        >
          <v-icon left>
            mdi-download
          </v-icon>
          <span class="text-truncate">
            {{ getFileName(attachment.name) }}
          </span>
        </v-chip>
      </span>
    </div>

    <v-divider />

    <p class="text-h6 my-5 text--carbon heading pa-3">
      Assignment Submission
    </p>

    <DisabledEditor :loading="loading" :text="submissionText" />

    <div v-if="submission.references">
      <p class="text-h6 my-5 text--carbon heading pa-3">
        Submission References
      </p>

      <DisabledEditor :text="submission.references" />
    </div>

    <div class="py-2">
      <v-chip
        v-for="attachment in submission.attachments"
        :key="attachment.id"
        class="mr-2 mb-2"
        color="aluminium"
        label
        text-color="primary"
        @click="downloadAttachment(attachment.id)"
      >
        <v-icon left>
          mdi-download
        </v-icon>
        <span class="text-truncate">
          {{ isDownloading ? "Downloading..." : getFileName(attachment.name) }}
        </span>
      </v-chip>
    </div>
  </div>
</template>

<script>
import DisabledEditor from "@/components/dashboard/assignments/DisabledEditor"

import urls from "@/utils/urls"
import filters from "@/utils/mixins/filters"

import split from "lodash/split"

export default {
  name: "SubmissionDetails",

  components: {
    DisabledEditor,
  },

  mixins: [filters],

  props: {
    assignment: {
      type: Object,
      required: true,
    },
    submission: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    isDownloading: false,
  }),

  computed: {
    submissionText() {
      return this.submission.text === "null" || this.submission.text === ""
        ? ""
        : this.submission.text
    },
  },

  methods: {
    async downloadAttachment(id) {
      this.isDownloading = true

      try {
        const response = await this.$http.get(
          urls.submissions.downloadAttachment(id)
        )
        window.open(response.data.data.url)
      } catch (e) {
        console.error(e)
      }

      this.isDownloading = false
    },

    getFileName(path) {
      /**
       * Input: submissions/my-file.txt
       * Output: my-file.txt
       */
      return split(path, "/").pop()
    },
  },
}
</script>

<style lang="scss" scoped>
.assignment-detail {
  background: white;
  border-radius: 5px;
  overflow: auto;
}

.heading {
  background: #e3ebef;
  border-radius: 5px;
}
</style>
