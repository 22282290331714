<template>
  <div>
    <v-row class="mb-10">
      <v-col>
        <v-skeleton-loader type="heading" />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        v-for="i in responsiveItems"
        :key="i"
        class="mb-10"
        cols="12"
        md="4"
      >
        <v-skeleton-loader
          :type="
            Math.random() > 0.5 ? 'list-item-two-line' : 'list-item-three-line'
          "
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "SkeletonLoading",

  props: {
    items: {
      type: Number,
      required: true,
    },
  },

  computed: {
    responsiveItems() {
      if (this.$vuetify.breakpoint.mdAndUp) return this.items
      else return Math.floor(this.items / 3)
    },
  },
}
</script>
