<template>
  <v-menu v-model="open" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        :class="{ 'menu-button': true, 'menu-button-expand': open }"
        v-on="on"
      >
        <v-icon class="btn-icon" large>mdi-segment</v-icon>
        <p class="view-all-text">View All Submissions</p>
      </div>
    </template>

    <v-list>
      <v-list-item
        v-for="(item, index) in submissionList"
        :key="index"
        :class="{
          'list-sub-item': true,
          primary: item.uuid === submissionId,
        }"
        color="red"
        @click="
          item.uuid === submissionId ? () => {} : $emit('change', item.uuid)
        "
      >
        <v-list-item-content
          :class="{
            'white--text': item.uuid === submissionId,
          }"
        >
          <v-list-item-title>
            Submission {{ submissionList.length - index }}
            {{ index === 0 ? "(Latest)" : "" }}
          </v-list-item-title>
          <v-list-item-subtitle
            :class="{
              'white--text': item.uuid === submissionId,
            }"
          >
            Score:
            {{ item.marks == null ? "Not scored yet" : item.marks.toFixed(2) }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "SubmissionsListMenu",

  components: {},

  props: {
    submissionId: {
      type: String,
      default: "",
    },
    submissionList: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    open: false,
  }),
}
</script>

<style lang="scss" scoped>
.list-sub-item {
  cursor: pointer;

  &:hover {
    background: #d2d2d2;
  }
}

.menu-button {
  width: 100px;
  height: 100%;
  cursor: pointer;
  outline: 1px solid #e3e3e3;
  border-radius: 5px;
  overflow: hidden;
  transition: 0.2s all ease-in;

  & > .btn-icon {
    height: 100%;
    width: 100px;
  }
  & > .view-all-text {
    display: inline;
  }

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    &:hover {
      width: 300px;
    }
  }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .menu-button-expand {
    width: 300px;
  }
}
</style>
