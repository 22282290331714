<template>
  <div
    v-bind="$attrs"
    :class="{
      root: true,
      plagiarised: isPlagiarised,
      notPlagiarised: !isPlagiarised,
    }"
  >
    <div>
      <slot />
    </div>

    <v-divider v-if="!isPlagiarised" class="mt-3" />

    <div class="btn mt-3">
      <p class="text">
        <span v-if="currentTypeText">
          {{ currentTypeText }}
        </span>
      </p>
      <div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" color="primary" outlined plain v-on="on">
              {{ isPlagiarised ? "Mark this as not plagiarised" : "Change" }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in options"
              :key="index"
              @click="handleChange(item)"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </div>
</template>

<script>
import { PLAGIARISM_OPTIONS, PLAGIARISM_TYPE } from "@/utils/constants"

export default {
  name: "PlagiarisedContent",

  props: {
    type: {
      default: PLAGIARISM_TYPE.PLAGIARISED,
      type: Number,
    },
  },

  data: () => ({}),

  computed: {
    isPlagiarised() {
      return this.type === PLAGIARISM_TYPE.PLAGIARISED
    },

    options() {
      return PLAGIARISM_OPTIONS.filter(x => x.value !== this.type)
    },

    currentTypeText() {
      if (this.isPlagiarised) return ""

      return PLAGIARISM_OPTIONS.find(x => x.value === this.type).title
    },
  },

  watch: {},

  mounted() {},

  methods: {
    handleChange(x) {
      this.$emit("update", x.value)
    },
  },
}
</script>

<style lang="scss" scoped>
.root {
  border: 1px solid;
  border-radius: 16px;
  padding: 16px;
}

.text {
  color: #64748b;
}

.plagiarised {
  background: #fdf9ec;
  border-color: #f7e4a6;
}
.notPlagiarised {
  border-color: #e2e8f0;
  background: #fff;
}

.btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
