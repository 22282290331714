<template>
  <div>
    <div class="review-container container-fixed-height">
      <v-row class="header" no-gutters>
        <v-col align-self="center" class="mx-6">
          <div class="text-h6">Review Submission</div>
          <span class="text-subtitle-2 font-weight-regular">
            Score: {{ review.marks ? review.marks : "Not yet scored" }}
          </span>
          <span
            v-if="review.status"
            class="text-subtitle-2 font-weight-regular text-capitalize"
          >
            | Status:
            {{ review.status.replace(/[-_]/g, " ") }}
            <span>
              <br />
              Reviewer: {{ review.professor }}
              <span v-if="review.date_published">
                |
                {{ moment(review.date_published).format("ll") }}
              </span>
            </span>
          </span>
        </v-col>

        <v-col cols="auto">
          <submissions-list-menu
            :submission-id="submissionId"
            :submission-list="submissionList"
            @change="$emit('change-submission', $event)"
          />
        </v-col>
      </v-row>

      <v-divider />

      <div class="content assignment-container-scrollbars px-6">
        <v-row no-gutters>
          <v-col class="mb-7">
            <v-chip
              class="mb-3 mt-5"
              color="#FBF0F0"
              label
              text-color="#A53232"
            >
              Plagiarism Detected
            </v-chip>

            <div
              v-for="(d, i) in data.submission_text"
              :key="`submission_text_${i}`"
            >
              <plagiarised-content
                class="mb-3"
                :type="d.type"
                @update="handleChange(i, 'submission_text', $event)"
              >
                <p>{{ d.text }}</p>
              </plagiarised-content>
            </div>
            <div
              v-for="(d, i) in data.submission_table"
              :key="`submission_table_${i}`"
            >
              <plagiarised-content
                class="mb-3"
                :type="d.type"
                @update="handleChange(i, 'submission_table', $event)"
              >
                <p>{{ d.text }}</p>
              </plagiarised-content>
            </div>
            <div
              v-for="(d, i) in data.submission_image"
              :key="`submission_image_${i}`"
            >
              <plagiarised-content
                class="mb-3"
                :type="d.type"
                @update="handleChange(i, 'submission_image', $event)"
              >
                <img :src="d.image" width="100%" />
              </plagiarised-content>
            </div>
            <v-row>
              <v-col cols="6" lg="4">
                <v-btn
                  block
                  class="font-weight-regular"
                  elevation="0"
                  outlined
                  @click="handleContinue"
                >
                  Continue Checking
                </v-btn>
              </v-col>

              <v-col cols="6" lg="4">
                <plagiarism
                  class="font-weight-regular"
                  :review-id="review.uuid"
                  :submission-id="submissionId"
                  @submit="handlePlagiarism"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import SubmissionsListMenu from "@/components/dashboard/assignments/SubmissionsListMenu"
import PlagiarisedContent from "@/components/dashboard/assignments/PlagiarisedContent"
import Plagiarism from "@/components/dashboard/assignments/Plagiarism"
import urls from "@/utils/urls"

export default {
  name: "ValidatePlagiarism",

  components: {
    Plagiarism,
    PlagiarisedContent,
    SubmissionsListMenu,
  },

  props: {
    /**
     * Data required to display the chunks of text/images which were plagiarised
     */
    data: {
      required: true,
      type: Object,
    },
    /**
     * Contains a list of all the submsissions for an assignment by a single user.
     */
    submissionList: {
      type: Array,
      required: true,
    },

    /**
     * Current submission's ID.
     */
    submissionId: {
      type: String,
      default: "",
    },

    review: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    isSpecificSubmission: true,
    canSubmit: true,
    reviewAlreadyExists: true,
    submitLoading: true,
  }),

  computed: {},

  watch: {},

  mounted() {},

  methods: {
    handleChange(index, key, type) {
      const newData = {
        ...this.data,
      }

      newData[key][index].type = type
      // console.log(this.data)
      this.$emit("update", newData)
    },

    submit() {
      this.$http.post(urls.plagiarism.create(this.submissionId), {
        review_id: this.review.uuid,
        plagiarism_details: [
          ...this.data.submission_text.map(x => ({
            plagiarism_coordinates: x.plagiarism_coordinates,
            content_type: "submissiontext",
            content_id: x.id,
            professor_remark: x.type,
          })),
          ...this.data.submission_table.map(x => ({
            plagiarism_coordinates: x.plagiarism_coordinates,
            content_type: "submissiontable",
            content_id: x.id,
            professor_remark: x.type,
          })),
          ...this.data.submission_image.map(x => ({
            plagiarism_coordinates: x.plagiarism_coordinates,
            content_type: "submissionimage",
            content_id: x.id,
            professor_remark: x.type,
          })),
        ],
      })
    },

    handlePlagiarism(data) {
      this.submit()
      this.$emit("plagiarism", data)
    },

    handleContinue() {
      this.submit()
      this.$emit("continue")
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  min-height: 100px;
}

.full-height {
  height: 100%;
}

.review-container {
  background: white;
  border-radius: 5px;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .content {
    height: calc(100% - 100px);
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
